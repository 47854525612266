/* eslint-disable */
import { routePaths } from './route-paths/route-paths';
import { urls } from './urls/urls';
import { permissions } from './permissions/permissions';
import { features } from './permissions/feature-flags';
import { iconTypes } from './icon-types/icon-types';
import { fontFamilies } from './font-families/font-families';
import { regexp } from './regexp/regexp';
import { countryCodes } from './country-codes/country-codes';
import { constants } from './constants/constants';

export const Config = {
  features: features,
  constants: constants,
  permissions: permissions,
  routePaths: routePaths,
  url: urls,
  regExp: regexp,
  countryCodes,
  fileStackApiKey: {
    general: () => Config.getFileStackApiKey() || 'Aoba6jBccRfypjdfz6rPLz',
    assetManager: () =>
      Config.getEnvironment() === 'development' ? 'Aoba6jBccRfypjdfz6rPLz' : 'Aigsv6Tv9QcynFjQHVyC0z',
  },
  stripePublishableKey: () => Config.getStripePublishableKey() || 'pk_test_yML6g9jrgBk7Qkx0wiCZRhSr',
  baseUrl: () =>
    Config.getEnvironment() === 'production'
      ? 'https://www.tintup.com'
      : `${window.location.protocol}//${window.location.hostname}`,
  twitchParentUrl: () => window.parent.location.hostname,
  apiVersion: '/v2',
  maxAssetUpload: 100,
  maxFileSize: 100 * 1024 * 1024,
  maxVideoFileSize: 1000 * 1024 * 1024,
  isDevEnv: () => Config.getEnvironmentVariable('NODE_ENV') === 'development',
  isStagingEnv: () => Config.getEnvironmentVariable('BASE_URL')?.includes('staging'),
  getTintPageUrl: (userId, slug) => `http://${userId}.tintpages.com/${slug}`,
  getTintUrl: slugName => `www.tintup.com/t/${slugName}`,
  localStorage: {
    keys: {
      GDPRComplianceLocalStorageName: 'GDPRCompliance',
      similarImagesLimit: 'similarImagesLimit',
    },
  },
  language: {
    defaultLanguage: 'en_US',
  },
  limits: {
    similarImagesLimit: 10,
    webhooksListMaxElements: 5,
  },
  getDomain() {
    return window.location.host.match(/^(\d+(?=\.tintpages\.(?:com|dev)$)|.+)/)[1];
  },
  getValidRedirectUrl: url => (!/^https?:\/\//i.test(url) ? 'http://' + url : url),
  getUniqueId: () =>
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15),
  getEnvironment: () => Config.getEnvironmentVariable('NODE_ENV'),
  getApiUrl: () => Config.getEnvironmentVariable('API_URL') || 'https://api.tintup.com',
  getPusherKey: () => Config.getEnvironmentVariable('PUSHER_KEY') || 'testPusherKey',
  getFacebookClientId: () => Config.getEnvironmentVariable('FACEBOOK_CLIENT_ID'),
  getLinkedInClientId: () => Config.getEnvironmentVariable('LINKEDIN_CLIENT_ID'),
  getChargifyServerHost: () => `https://${Config.getEnvironmentVariable('CHARGIFY_DOMAIN')}.chargify.com`,
  getChargifyPublicKey: () => Config.getEnvironmentVariable('CHARGIFY_PUBLIC_KEY'),
  getFileStackApiKey: () => Config.getEnvironmentVariable('FILESTACK_API_KEY') || 'APlRkFeSpQhGlhLqd8VZfz',
  getStripePublishableKey: () => Config.getEnvironmentVariable('STRIPE_PUBLISHABLE_KEY'),
  getEnvironmentVariable: key => (window._env_ ? window._env_[key] : undefined),
  getEnvironmentVariables: key => (window._env_ ? window._env_ : {}),

  getMinifiedFileStackImage: (url, resize) =>
    `https://cdn.filestackcontent.com/${Config.fileStackApiKey.general() || 'APlRkFeSpQhGlhLqd8VZfz'}${
      resize ? '/' + resize : ''
    }/auto_image/compress/${url}`,
  getFileStackCdnUrl: url =>
    `https://cdn.filestackcontent.com/${Config.fileStackApiKey.general() || 'APlRkFeSpQhGlhLqd8VZfz'}/${url}`,
  getGoogleFontSource: fontName =>
    `https://cdn.filestackcontent.com/${Config.getFileStackApiKey()}/https://fonts.googleapis.com/css2?family=${
      fontName.includes(' ') ? fontName.replace(' ', '+') : fontName
    }&display=swap`,
  assets: {
    tintLogo: '//cdn.hypemarks.com/assets/splash5/Logo_Icon_warmRed_@2x.png',
    tintLogoSVG: '/public/js/react/assets/images/tint_logo.svg',
    customImage: 'https://cdn.filestackcontent.com/432WLTvQYmfoiRvGAP38',
    formBuilder: {
      templates: {
        blank: '/public/js/react/assets/form-thumbnails/blank.svg',
        feedback: '/public/js/react/assets/form-thumbnails/feedback.svg',
        form: '/public/js/react/assets/form-thumbnails/form.svg',
        productReview: '/public/js/react/assets/form-thumbnails/product-review.svg',
        publicPost: '/public/js/react/assets/form-thumbnails/public-post.svg',
        survey: '/public/js/react/assets/form-thumbnails/survey.svg',
      },
    },
  },
  googleAccountApiKey: {
    prodEnv: 'AIzaSyDu4CmGJ0EaCzZCaJbXrgrhE-BTJ9WIiEI',
    devEnv: 'AIzaSyCLJhHsH27UExFkWKcu6j_RqPO_soS0qkI',
  },
  chromecastAppId: () => Config.getEnvironmentVariable('CHROMECAST_APP_ID'),
  chromecastNamespace: 'urn:x-cast:com.google.cast.sample.helloworld',
  assetsPath: {
    prodEnv: 'https://www.tintup.com/public/js/react/assets',
    devEnv: 'https://www.staging.tintup.com/public/js/react/assets',
  },
  fonts: {
    secondaryFont: 'Lato',
  },
  webSocketId: {
    prodEnv: '1462fd2021caddb4470c',
    devEnv: 'e888d59ed1b28ec834d9',
  },
  defaultAssetPageSize: 25,
  defaultCollectionPageSize: 24,
  defaultInfiniteListPageSize: 25,
  defaultSubmissionsPageSize: 25,
  defaultTintsPageSize: 25,
  defaultPostsPageSize: 20,
  defaultCustomFontsPageSize: 25,
  defaultCustomFontsDropdownSize: 500,
  defaultEmailDomainsPageSize: 25,
  defaultRightRequestsPageSize: 25,
  defaultAccountsPageSize: 25,
  defaultPageSize: 25,
  defaultProductsPageSize: 25,
  defaultFeedsPageSize: 25,
  defaultMarketingIntegrationsPageSize: 25,
  passwordExpiredUrl: 'credentials/password_expired',
  fontFamilyList: fontFamilies,
  monthList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  iconsTypes: iconTypes,
  keyboardNumbers: {
    arrowDown: 40,
    arrowUp: 38,
    arrowLeft: 37,
    arrowRight: 39,
    esc: 27,
  },
  screenResolution: {
    xsm: 480,
    smm: 768,
    mdm: 992,
    xl: 1170,
    xxl: 1440,
  },
};
